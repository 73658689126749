<!-- eslint-disable vue/max-len -->
<template>
  <div :class="{ hidden: scrolledDown }" class="bg-yellow-500 text-black py-2 px-4 text-white w-full z-50 shadow-md">
    <div class="container mx-auto text-center">
      <p class="text-sm font-semibold">☀️ Looking for Fentrica for Solar Parks? ☀️ <a href="https://fentrica.solar" class="font-bold underline">See here</a></p>
    </div>
  </div>
  <div
    v-if="!loading && currentLocale && global && nav"
    class="menu flex left-0 top-0 z-40 flex w-full justify-center items-center transition-all"
    :class="{
      '!top-10': !scrolledDown,
      sticky: scrolledDown,
      absolute: !scrolledDown,
      'bg-transparent': !scrolledDown,
      'bg-white': scrolledDown,
      'shadow-lg': scrolledDown,
      'border-bottom': scrolledDown,
    }">
    <section class="container">
      <div class="relative flex items-center justify-between top-0">
        <div class="w-60 max-w-full px-4">
          <a :href="`/${currentLocale.code}/home`" @click.prevent="route(`/${currentLocale.code}/home`)" class="block w-full py-5">
            <img :src="require('@/assets/fentrica-white.svg')" alt="logo" :class="{ hidden: scrolledDown }">
            <img :src="require('@/assets/fentrica.svg')" :class="{ hidden: !scrolledDown }" alt="logo">
          </a>
        </div>
        <div class="flex w-full items-center justify-between px-4">
          <div>
            <button class="navtoggle-btn" aria-label="Navigation" type="button" @click.prevent="toggleMenu()">
              <span class="navtoggle-stripe" :class="{ 'navtoggle-stripe-scrolled': scrolledDown }" />
              <span class="navtoggle-stripe" :class="{ 'navtoggle-stripe-scrolled': scrolledDown }" />
              <span class="navtoggle-stripe" :class="{ 'navtoggle-stripe-scrolled': scrolledDown }" />
            </button>
            <nav ref="nav" class="navbar hidden lg:block">
              <div class="flex items-end -mt-2 mr-2 justify-end lg:hidden">
                <Locale />
              </div>
              <ul class="lg:flex">
                <li class="group relative" v-for="item of nav" :key="item.id">
                  <a
                    v-if="!item.items?.length"
                    :class="{ 'nav-item-scrolled': scrolledDown, 'nav-item-start': !scrolledDown }"
                    :href="item.type === 'EXTERNAL' ? item.path : `/${currentLocale?.code}${item.path}`"
                    @click.prevent="route(item.type === 'EXTERNAL' ? item.path : `/${currentLocale?.code}${item.path}`)"
                    :target="item.type === 'EXTERNAL' ? '_blank' : '_self'"
                    class="nav-item"
                  >
                    {{ item.title }}
                  </a>
                  <a
                    v-if="item.items?.length"
                    :class="{ 'nav-item-scrolled': scrolledDown, 'nav-item-start': !scrolledDown }"
                    href="#"
                    @click.prevent=""
                    class="nav-item"
                  >
                    {{ item.title }}
                    <fa-icon class="ml-2 hidden lg:inline-block" icon="chevron-down" />
                  </a>
                  <div class="submenu lg:hidden block mb-6" v-if="item.items?.length">
                    <div class="submenu-content">
                      <a
                        v-for="subitem of item.items"
                        class="flex p-2 lg:p-4 group/navitem lg:rounded-lg cursor-pointer hover:bg-surface-50 hover:text-primary"
                        :href="subitem.type === 'EXTERNAL' ? subitem.path : `/${currentLocale?.code}${subitem.path}`"
                        @click.prevent="route(subitem.type === 'EXTERNAL' ? subitem.path : `/${currentLocale?.code}${subitem.path}`)"
                        :key="subitem.id"
                      >
                        <div class="icon text-primary-300 group-hover/navitem:text-primary mx-4 flex flex-wrap justify-center align-center content-center">
                          <fa-icon :icon="subitem.faIcon" size="xl" />
                        </div>
                        <div class="link w-full">
                          <div class="text-primary"><span>{{ subitem.title }}</span></div>
                          <p class="text-xs text-slate-600">{{ subitem.description }}</p>
                        </div>
                        <div class="flex flex-wrap justify-center align-center content-center">
                          <fa-icon class="ml-2 invisible group-hover/navitem:visible inline-block" icon="chevron-right" />
                        </div>
                      </a>
                    </div>
                  </div>
                </li>
              </ul>
              <div class="flex items-center justify-center lg:hidden p-4">
                <a href="https://app.fentrica.com" target="_blank" rel="noopener" class="btn-login w-full">{{global.loginButton}}</a>
              </div>
            </nav>
          </div>
        </div>
        <div class="hidden lg:block">
          <Locale class="pr-2" :class="{ 'text-white': !scrolledDown }" />
        </div>
        <div class="hidden lg:block">
          <a href="https://app.fentrica.com" target="_blank" rel="noopener" class="btn-login">{{global.loginButton}}</a>
        </div>
      </div>
    </section>
  </div>
</template>

<style lang="scss" scoped>
@tailwind components;

.navbar {
  max-height: calc(100vh - 5rem);
}
.submenu-content {
  max-height: calc(100vh - 5rem);
}

@layer components {
  .nav-item {
    @apply px-8 py-2 inline-block text-base font-medium lg:mr-0 lg:inline-flex lg:px-4 lg:py-0 lg:group-hover:opacity-70 items-center text-lg;
  }
  .nav-item-scrolled {
    @apply text-primary group-hover:text-primary
  }
  .nav-item-start {
    @apply lg:text-white lg:group-hover:text-white text-primary group-hover:text-primary
  }
  .navbar {
    @apply lg:static overflow-scroll lg:overflow-visible absolute uppercase border lg:border-0 right-0 mt-1 lg:mt-0 top-full w-full max-w-[100vw] rounded-lg bg-white py-5 shadow-lg lg:w-full lg:max-w-full lg:bg-transparent lg:px-4 lg:py-0 lg:shadow-none xl:px-6
  }
  .navtoggle-btn {
    @apply absolute outline-none outline-0 right-0 top-1/2 block -translate-y-1/2 rounded-lg px-3 py-[6px] ring-primary lg:hidden
  }
  .navtoggle-stripe {
    @apply relative my-[6px] block h-[2px] w-[30px] bg-white;
  }
  .navtoggle-stripe-scrolled {
    @apply bg-primary
  }
  .submenu {
    @apply relative left-0 top-full p-0 lg:p-2 transition-[top] duration-300 group-hover:opacity-100 lg:invisible lg:absolute lg:top-[120%] lg:block lg:opacity-0 lg:group-hover:visible lg:group-hover:top-full;
  }
  .submenu-content {
    @apply lg:border overflow-visible lg:overflow-scroll lg:rounded-md bg-white lg:shadow-xl lg:w-[50vw] h-full lg:p-4 w-auto;
  }
  .btn-login {
    @apply inline-flex text-nowrap items-center justify-center rounded-lg bg-green px-6 py-2 text-center text-base font-medium shadow hover:bg-green-400
  }

}
</style>

<script lang="ts">
import { Component, ComponentBase } from '@/component-base';
import Locale from './locale.vue';

@Component({
  components: {
    Locale,
  },
  watch: {
    $route() {
      this.routeChange();
    },
  },
})
export default class App extends ComponentBase {
  declare $refs: { nav: HTMLDivElement };

  private lastScrollPosition = 0;

  public loading = false;

  public scrolledDown = false;

  public get nav() {
    return this.AppState.nav;
  }

  public get global() {
    return this.AppState.global;
  }

  public toggleMenu() {
    this.$refs.nav.classList.toggle('hidden');
  }

  public created() {
    window.addEventListener('scroll', this.onScroll);
  }

  public async routeChange() {
    window.scrollTo({ top: 0, behavior: 'auto' });
    this.loading = true;
    this.onScroll();
    await this.$nextTick();
    this.loading = false;
  }

  public onScroll() {
    const currentScrollPosition = window.scrollY ?? document.documentElement.scrollTop ?? window.pageYOffset;
    // Because of momentum scrolling on mobiles, we shouldn't continue if it is less than zero
    if (currentScrollPosition < 0) {
      return;
    }
    if (currentScrollPosition > this.lastScrollPosition) {
      // scrolling down
      if (currentScrollPosition > this.lastScrollPosition + 50) {
        this.scrolledDown = true;
        this.lastScrollPosition = currentScrollPosition;
      }
    } else if (
      currentScrollPosition <= 50
        && currentScrollPosition < this.lastScrollPosition
    ) {
      if (currentScrollPosition < this.lastScrollPosition - 50) {
        this.scrolledDown = false;
        this.lastScrollPosition = currentScrollPosition;
      }
    }
  }

  public get currentLocale() {
    return this.AppState.currentLocale;
  }

  public async route(route: string) {
    if (route.startsWith('http://') || route.startsWith('https://')) {
      window.open(route, '_blank');
      return;
    }
    window.scrollTo({ top: 0, behavior: 'auto' });
    this.onScroll();
    await this.$router.push(route);
  }
}
</script>
